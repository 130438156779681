'use strict';

$(document).ready(function () {
    const accordions = $('.homepage-layout-accordion-list-wrapper');

    accordions.toArray().forEach(function (accordion) {
        $(accordion).find('.homepage-layout-accordion-item-container').first().addClass('show');
    });

    $('.homepage-layout-accordion-item-container').on('click', function (e) {
        $(e.currentTarget).toggleClass('show');
    });
});
